import { Camera } from 'three';
import {
  Burner,
  Color,
  Cupboard,
  Line,
  Model,
  Oven,
  RangeTop,
  Spacer,
  Trim,
} from './Enums.js';

/**
 * The configured state of the Lacanche range. This includes the chosen line,
 * range top, trim, color, and optional burners
 */
export class State {
  // This is the initial state of the Lacanche range

  /**
   * The line of the range (classique or moderne)
   * @type {string}
   */
  model = Model.sully;

  /**
   * The line of the range (classique or moderne)
   * @type {string}
   */
  line = Line.classique;

  /**
   * The style of the range top (classique, traditional, or 4-feux)
   * @type {string}
   */
  top = RangeTop.classique;

  /**
   * The trim of the range (brass, brushedSS, chrome, or nickel)
   * @type {string}
   */
  trim = Trim.brass;

  /**
   * The color of the range (too many to list)
   * @type {string}
   */
  color = Color.burgundyRed;

  /**
   * The type of left oven (gas, electric, or electric convection)
   * @type {string}
   */
  leftOven = Oven.gas;

  /**
   * The type of left oven (gas, electric, or electric convection)
   * @type {string}
   */
  rightOven = Oven.convection;

  /**
   * The type of left cupboard (warming or storage)
   * @type {string}
   */
  leftCupboard = Cupboard.warming;

  /**
   * The type of right cupboard (warming or storage)
   * @type {string}
   */
  rightCupboard = Cupboard.warming;

  /**
   * The position of the back spacer (raised or flush)
   * @type {string}
   */
  spacer = Spacer.raised;

  /**
   * The first (leftmost) optional "burner" for the range top
   * @type {string}
   */
  option1 = Burner.stainlessSteelWorkstation;

  /**
   * The second optional "burner" for the range top
   * @type {string}
   */
  option2 = Burner.stainlessSteelWorkstation;

  /**
   * The third optional "burner" for the range top (only available on Sully 1800 and 2200)
   * @type {string}
   */
  option3 = Burner.stainlessSteelWorkstation;

  /**
   * The fourth optional "burner" for the range top (only available on Sully 2200)
   * @type {string}
   */
  option4 = Burner.stainlessSteelWorkstation;

  /**
   * The camera used to view the scene
   * @type {Camera}
   */
  camera;
}
