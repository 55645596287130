import { Group } from 'three';

import { BaseKnobs } from '../shared/BaseKnobs';
import { KnobModels } from '../shared/KnobModels';

import { Burner, Cupboard, Oven, Region } from '../../shared/Enums';
import { Materials } from '../../shared/Materials';
import { State } from '../../shared/State';

export class Knobs extends BaseKnobs {
  /**
   * The knobs that selected but not necessarily displayed (yet) on the range
   * @type {Object.<string, Object.<string, ?string>>}
   */
  selectedKnobs = {
    farLeft: {
      optionBack: null,
      optionFront: null,
      cupboard: Cupboard.warming,
    },
    center: {
      petiteOven: Oven.petite,
      leftOven: Oven.electric,
      baseLeftBurnerFront: Burner.gasBurner,
      baseLeftBurnerBack: Burner.gasBurner,
      baseBurnerCenter: Burner.gasBurner,
      baseRightBurnerFront: Burner.gasBurner,
      baseRightBurnerBack: Burner.gasBurner,
      rightOven: Oven.gas,
    },
    farRight: {
      cupboard: Cupboard.warming,
      optionBack: null,
      optionFront: null,
    },
  };

  /**
   * @param {KnobModels} knobModels
   * @param {Group} range
   * @param {State} state
   * @param {Materials} materials
   */
  constructor(knobModels, range, state, materials) {
    super(knobModels, range, state, materials);

    this.updateFarLeftKnobs();
    this.updateCenterKnobs();
    this.updateFarRightKnobs();
  }

  /**
   * Change the selected cupboard to have a knob for a warming cupboard
   * @param {string} region
   */
  selectWarmingCupboard(region) {
    switch (region) {
      case Region.farLeft:
        this.selectedKnobs.farLeft.cupboard = Cupboard.warming;
        this.updateFarLeftKnobs();
        break;

      case Region.farRight:
        this.selectedKnobs.farRight.cupboard = Cupboard.warming;
        this.updateFarRightKnobs();
        break;
    }
  }

  /**
   * Remove the warming cupboard knob from the selected region
   * @param {string} region
   */
  selectStorageCupboard(region) {
    switch (region) {
      case Region.farLeft:
        this.selectedKnobs.farLeft.cupboard = null;
        this.updateFarLeftKnobs();
        break;

      case Region.farRight:
        this.selectedKnobs.farRight.cupboard = null;
        this.updateFarRightKnobs();
        break;
    }
  }

  /** Update the knobs on the range's far left */
  updateFarLeftKnobs() {
    this.updateKnobRegion(Region.farLeft, {
      maxKnobs: 3,
      offset: 0.2,
      gap: 0.1,
    });
  }

  /** Update the knobs in the range's center */
  updateCenterKnobs() {
    this.updateKnobRegion(Region.center, {
      maxKnobs: 8,
      offset: 0.565,
      gap: 0.115,
    });
  }

  /** Update the knobs on the range's far right */
  updateFarRightKnobs() {
    this.updateKnobRegion(Region.farRight, {
      maxKnobs: 3,
      offset: 1.52,
      gap: 0.1,
    });
  }

  /**
   * Change the right oven to gas or electric
   * @param {string} ovenType
   */
  changeRightOven(ovenType) {
    this.selectedKnobs.center.rightOven = ovenType;
    this.updateCenterKnobs();
  }
}
