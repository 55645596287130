import { BufferGeometry, Group, Line, LineBasicMaterial, Vector3 } from 'three';
// @ts-ignore
import { Text } from 'troika-three-text';

import { Burner, Cupboard, LabelName, Oven } from '../../shared/Enums';
import { State } from '../../shared/State';

export class Labels {
  /** All range front labels should be added to (or removed from) this group */
  front = new Group();

  /** All range top labels should be added to (or removed from) this group */
  top = new Group();

  config = {
    leftCupboard: null,
    leftOven: null,
    leftStorageDrawer: null,
    petiteOven: null,
    rightCupboard: null,
    rightOven: null,
    rightStorageDrawer: null,
    verticalOven: null,
  };

  /**
   * The current configuration of the range
   * @type {State}
   */
  state;

  /**
   * @param {State} state
   */
  constructor(state) {
    this.state = state;

    // Hide labels by default
    this.front.visible = false;
    this.top.visible = false;
  }

  /**
   * The text to be used for the various labels
   * @param {string} rangePart
   * @returns {string}
   */
  textForPart(rangePart) {
    const partDescriptions = {
      [Burner.eighteenK]: '18,000 BTU',
      [Burner.elevenK]: '11,000 BTU',
      [Burner.fiveK]: '5,000 BTU',
      [Cupboard.storage]: 'Storage Cupboard',
      [Cupboard.warming]: 'Warming Cupboard',
      [Oven.convection]: `Electric Convection Oven\nw/ broiler`,
      [Oven.convectionAlt]: `Electric Convection Oven\nw/ broiler`,
      [Oven.electric]: `Electric Oven\nw/ broiler`,
      [Oven.gas]: `Gas Oven\nw/o broiler`,
      [Oven.leftVertConv]: `Vertical Electric Convection Oven\nw/ broiler`,
      [Oven.petite]: `Petit Oven\nw/ broiler`,
      [Oven.rightVertConv]: `Vertical Electric Convection Oven\nw/ broiler`,
    };

    return partDescriptions[rangePart];
  }

  changeColor() {
    this.front.children.forEach((label) => {
      // @ts-ignore
      label.color = this.#frontLabelColor();
    });
  }

  changeText(objectName, part) {
    // @ts-ignore
    this.front.getObjectByName(objectName).text = this.textForPart(part);
  }

  addRangeLabels() {
    this.#addLeftOvenLabel();
    this.#addRightOvenLabel();
    this.#addLeftCupboardLabel();
    this.#addRightCupboardLabel();
    this.#addPetiteOvenLabel();
    this.#addVerticalOvenLabel();
    this.#addLeftStorageDrawerLabel();
    this.#addRightStorageDrawerLabel();
  }

  tick() {
    this.top.children.forEach((label) => {
      // @ts-ignore
      if (!label.isLine) {
        label.setRotationFromEuler(this.state.camera.rotation);
      }
    });
  }

  /**
   * Add descriptive labels for the base 3 feux burners
   * @param {array} position
   */
  add3FeuxLabels(position) {
    const eighteenKPos = [position[0] - 0.21, position[1], position[2] - 0.08];
    this.#addBurnerLabel(eighteenKPos, Burner.eighteenK);
    this.#drawLine(
      [eighteenKPos[0], eighteenKPos[1] - 0.03, eighteenKPos[2]],
      [eighteenKPos[0], eighteenKPos[1] - 0.1, eighteenKPos[2] + 0.08]
    );

    const fiveKPos = [
      position[0] + 0.09,
      position[1] + 0.08,
      position[2] - 0.18,
    ];
    this.#addBurnerLabel(fiveKPos, Burner.fiveK);
    this.#drawLine(
      [fiveKPos[0], fiveKPos[1] - 0.03, fiveKPos[2]],
      [fiveKPos[0] + 0.05, fiveKPos[1] - 0.18, fiveKPos[2] + 0.05]
    );

    const elevenKPos = [position[0] + 0.2, position[1], position[2] + 0.07];
    this.#addBurnerLabel(elevenKPos, Burner.elevenK);
    this.#drawLine(
      [elevenKPos[0], elevenKPos[1] - 0.03, elevenKPos[2]],
      [elevenKPos[0] - 0.05, elevenKPos[1] - 0.1, elevenKPos[2] + 0.08]
    );
  }

  /**
   * Add descriptive labels for 2 15K optional burners
   * @param {array} position
   */
  addTwo15KBurnersLabels(position) {
    const fiveKPos = [position[0], position[1], position[2]];
    this.#addBurnerLabel(fiveKPos, Burner.fiveK);
    this.#drawLine(
      [fiveKPos[0], fiveKPos[1] - 0.05, fiveKPos[2]],
      [fiveKPos[0] + 0.05, fiveKPos[1] - 0.23, fiveKPos[2]]
    );
  }

  /**
   * Draw a line from the label to the burner
   * @param {array} from
   * @param {array} to
   */
  #drawLine(from, to) {
    const fromVector = new Vector3(...from);
    const toVector = new Vector3(...to);
    const material = new LineBasicMaterial({ color: 0xffffff, linewidth: 2 });
    const geometry = new BufferGeometry().setFromPoints([fromVector, toVector]);
    const line = new Line(geometry, material);

    this.top.add(line);
  }

  /**
   * Add a label for the 18k burner
   * @param {array} position
   * @param {string} burner
   */
  #addBurnerLabel(position, burner) {
    const burnerLabel = this.#addTopLabel(this.textForPart(burner), position);

    this.top.add(burnerLabel);
  }

  /**
   * Add a label for the 18k burner
   * @param {array} position
   */
  #add11KBurnerLabel(position) {
    const burnerLabel = this.#addTopLabel(
      this.textForPart(Burner.elevenK),
      position
    );

    this.top.add(burnerLabel);
  }

  /**
   * Create an SDF texture to label a part of the range
   * @param {string} text
   * @param {array} position
   * @returns {Text}
   */
  #addTopLabel(text, position) {
    const label = new Text();
    label.text = text;
    label.font = '/assets/fonts/roboto-v47-latin-regular.woff';
    label.fontSize = 0.043;
    label.lineHeight = 1.25;
    label.position.set(...position);
    label.rotation.x = -Math.PI / 2; // rotate to be flat on the range
    label.color = 0x444444;
    label.outlineColor = 0xffffff;
    label.outlineWidth = '10%';
    label.anchorX = 'center';
    label.anchorY = 'middle';
    label.textAlign = 'center';
    label.maxWidth = 0.3;

    label.sync();

    return label;
  }

  #addLeftOvenLabel() {
    const ovenLabel = this.#addFrontLabel(
      this.textForPart(this.state.leftOven),
      LabelName.leftOven,
      this.config.leftOven.position,
      this.config.leftOven.width,
      this.#frontLabelColor()
    );

    this.front.add(ovenLabel);
  }

  #addRightOvenLabel() {
    if (this.config.rightOven === null) return;

    const ovenLabel = this.#addFrontLabel(
      this.textForPart(this.state.rightOven),
      LabelName.rightOven,
      this.config.rightOven.position,
      this.config.rightOven.width,
      this.#frontLabelColor()
    );

    this.front.add(ovenLabel);
  }

  #addLeftCupboardLabel() {
    if (this.config.leftCupboard === null) return;

    const cupboard = this.#addFrontLabel(
      this.textForPart(this.state.leftCupboard),
      LabelName.leftCupboard,
      this.config.leftCupboard.position,
      this.config.leftCupboard.width,
      this.#frontLabelColor()
    );

    this.front.add(cupboard);
  }

  #addRightCupboardLabel() {
    if (this.config.rightCupboard === null) return;

    const cupboard = this.#addFrontLabel(
      this.textForPart(this.state.rightCupboard),
      LabelName.rightCupboard,
      this.config.rightCupboard.position,
      this.config.rightCupboard.width,
      this.#frontLabelColor()
    );

    this.front.add(cupboard);
  }

  #addPetiteOvenLabel() {
    if (this.config.petiteOven === null) return;

    const ovenLabel = this.#addFrontLabel(
      this.textForPart(Oven.petite),
      LabelName.rightOven,
      this.config.petiteOven.position,
      this.config.petiteOven.width,
      this.#frontLabelColor()
    );

    this.front.add(ovenLabel);
  }

  #addVerticalOvenLabel() {
    if (this.config.verticalOven === null) return;

    const ovenLabel = this.#addFrontLabel(
      this.textForPart(Oven.rightVertConv),
      LabelName.verticalConvection,
      this.config.verticalOven.position,
      this.config.verticalOven.width,
      this.#frontLabelColor()
    );

    this.front.add(ovenLabel);
  }

  #addLeftStorageDrawerLabel() {
    if (this.config.leftStorageDrawer === null) return;

    const drawerLabel = this.#addFrontLabel(
      'Storage Drawer',
      LabelName.storageDrawer,
      this.config.leftStorageDrawer.position,
      this.config.leftStorageDrawer.width,
      this.#frontLabelColor()
    );

    this.front.add(drawerLabel);
  }

  #addRightStorageDrawerLabel() {
    if (this.config.rightStorageDrawer === null) return;

    const drawerLabel = this.#addFrontLabel(
      'Storage Drawer',
      LabelName.storageDrawer,
      this.config.rightStorageDrawer.position,
      this.config.rightStorageDrawer.width,
      this.#frontLabelColor()
    );

    this.front.add(drawerLabel);
  }

  /**
   * Create an SDF texture to label a part of the range
   * @param {string} text
   * @param {string} name
   * @param {array} position
   * @param {number} maxWidth
   * @param {string|number} color
   * @returns {Text}
   */
  #addFrontLabel(text, name, position, maxWidth, color) {
    const label = new Text();
    label.name = name;
    label.text = text;
    label.font = '/assets/fonts/roboto-v47-latin-regular.woff';
    label.fontSize = 0.043;
    label.lineHeight = 1.25;
    label.position.set(...position);
    label.color = color;
    label.anchorX = 'center';
    label.anchorY = 'middle';
    label.textAlign = 'center';
    label.maxWidth = maxWidth;

    label.sync();

    return label;
  }

  #frontLabelColor() {
    const lightColors = [
      'chantilly',
      'englishCreme',
      'faience',
      'ivory',
      'roseQuartz',
      'tilleul',
      'vertSilice',
      'white',
    ];

    const midColors = [
      'armor',
      'coralBlue',
      'delftBlue',
      'frangipane',
      'mandarine',
      'provenceYellow',
      'vertSologne',
    ];

    if (lightColors.includes(this.state.color)) {
      return 0x444444;
    } else if (midColors.includes(this.state.color)) {
      return 0xdddddd;
    } else {
      return 0x999999;
    }
  }
}
