import { Group } from 'three';

import { BaseKnobs } from '../shared/BaseKnobs';
import { KnobModels } from '../shared/KnobModels';

import { Burner, Cupboard, Oven, Region } from '../../shared/Enums';
import { Materials } from '../../shared/Materials';
import { State } from '../../shared/State';

export class Knobs extends BaseKnobs {
  /**
   * The knobs that selected but not necessarily displayed (yet) on the range
   * @type {Object.<string, Object.<string, ?string>>}
   */
  selectedKnobs = {
    center: {
      leftOven: Oven.gas,
      baseLeftBurnerFront: Burner.gasBurner,
      baseLeftBurnerBack: Burner.gasBurner,
      baseBurnerCenter: Burner.gasBurner,
      baseRightBurnerFront: Burner.gasBurner,
      baseRightBurnerBack: Burner.gasBurner,
      rightOven: Oven.electric,
    },
    farRight: {
      cupboard: Cupboard.warming,
      optionBack: null,
      optionFront: null,
    },
  };

  /**
   * @param {KnobModels} knobModels
   * @param {Group} range
   * @param {State} state
   * @param {Materials} materials
   */
  constructor(knobModels, range, state, materials) {
    super(knobModels, range, state, materials);

    this.updateCenterKnobs();
    this.updateFarRightKnobs();
  }

  /**
   * Add a warming cupboard knob
   * @param {string} [region] - Ignored since there is only one cupboard
   */
  selectWarmingCupboard(region) {
    this.selectedKnobs.farRight.cupboard = Cupboard.warming;
    this.updateFarRightKnobs();
  }

  /**
   * Remove the warming cupboard knob
   * @param {string} region - Ignored since there is only one cupboard
   */
  selectStorageCupboard(region) {
    this.selectedKnobs.farRight.cupboard = null;
    this.updateFarRightKnobs();
  }

  /** Update the knobs on the range's left */
  updateCenterKnobs() {
    this.updateKnobRegion(Region.center, {
      maxKnobs: 7,
      offset: 0.44,
      gap: 0.11,
    });
  }

  /** Update the knobs on the range's far left */
  updateFarRightKnobs() {
    this.updateKnobRegion(Region.farRight, {
      maxKnobs: 3,
      offset: 1.35,
      gap: 0.1,
    });
  }

  /**
   * Change the left oven to gas or electric
   * @param {string} ovenType
   */
  changeLeftOven(ovenType) {
    this.selectedKnobs.center.leftOven = ovenType;
    this.updateCenterKnobs();
  }

  /**
   * Change the right oven to gas or electric
   * @param {string} ovenType
   */
  changeRightOven(ovenType) {
    this.selectedKnobs.center.rightOven = ovenType;
    this.updateCenterKnobs();
  }
}
