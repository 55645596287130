import { Group } from 'three';

import { BaseKnobs } from '../shared/BaseKnobs';
import { KnobModels } from '../shared/KnobModels';

import { Burner, Cupboard, Oven, Region } from '../../shared/Enums';
import { Materials } from '../../shared/Materials';
import { State } from '../../shared/State';

export class Knobs extends BaseKnobs {
  /**
   * The knobs that selected but not necessarily displayed (yet) on the range
   * @type {Object.<string, Object.<string, ?string>>}
   */
  selectedKnobs = {
    farLeft: {
      optionBack: null,
      optionFront: null,
      cupboard: Cupboard.warming,
    },
    center: {
      petiteOven: Oven.petite,
      leftOven: Oven.electric,
      baseLeftBurnerFront: Burner.gasBurner,
      baseLeftBurnerBack: Burner.gasBurner,
      baseBurnerCenter: Burner.gasBurner,
      baseRightBurnerFront: Burner.gasBurner,
      baseRightBurnerBack: Burner.gasBurner,
      rightOven: Oven.gas,
    },
  };

  /**
   * @param {KnobModels} knobModels
   * @param {Group} range
   * @param {State} state
   * @param {Materials} materials
   */
  constructor(knobModels, range, state, materials) {
    super(knobModels, range, state, materials);
  }

  /**
   * Add a warming cupboard knob
   * @param {string} [region] - Ignored since there is only one cupboard
   */
  selectWarmingCupboard(region) {
    this.selectedKnobs.farLeft.cupboard = Cupboard.warming;
    this.updateFarLeftKnobs();
  }

  /**
   * Remove the warming cupboard knob
   * @param {string} region - Ignored since there is only one cupboard
   */
  selectStorageCupboard(region) {
    this.selectedKnobs.farLeft.cupboard = null;
    this.updateFarLeftKnobs();
  }

  /** Update the knobs on the range's far left */
  updateFarLeftKnobs() {
    this.updateKnobRegion(Region.farLeft, {
      maxKnobs: 3,
      offset: 0.39,
      gap: 0.1,
    });
  }

  /** Update the knobs on the range's left */
  updateCenterKnobs() {
    this.updateKnobRegion(Region.center, {
      maxKnobs: 7,
      offset: 0.83,
      gap: 0.11,
    });
  }

  /**
   * Change the right oven to gas or electric
   * @param {string} ovenType
   */
  changeRightOven(ovenType) {
    this.selectedKnobs.center.rightOven = ovenType;
    this.updateCenterKnobs();
  }
}
