import { State } from './State';

export class Summary {
  /**
   * The current state of the world.
   * @type {State}
   */
  #state;

  /**
   * @param {State} state
   */
  constructor(state) {
    this.#state = state;
  }

  model() {
    return `Lacanche ${this.#state.model}`;
  }

  line() {
    return `${this.#capitalizeFirstLetter(this.#state.line)} line`;
  }

  /**
   * @param {string} val
   * @returns {string}
   */
  #capitalizeFirstLetter(val) {
    return String(val).charAt(0).toUpperCase() + String(val).slice(1);
  }
}
