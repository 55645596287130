import { Group, Object3D } from 'three';
import { MeshName } from '../../shared/Enums';

/**
 * Various external (common) parts of the Volnay range
 * @param {Group} meshes - Common parts of most ranges
 * @returns {Object3D[]}
 */
export function parts(meshes) {
  // Cupboard Door
  const cupboardDoor = meshes
    .getObjectByName(MeshName.volnayCupboardDoor)
    .clone();
  cupboardDoor.name = MeshName.cupboardDoor;

  // Oven Door
  const ovenDoor = meshes.getObjectByName(MeshName.volnayOvenDoor).clone();
  ovenDoor.name = MeshName.ovenDoor;

  // Storage Drawer
  const storageDrawer = meshes
    .getObjectByName(MeshName.volnayStorageDrawer)
    .clone();
  storageDrawer.name = MeshName.storageDrawer;

  // Oven Vent Cover
  const leftOvenVentCover = meshes
    .getObjectByName(MeshName.ovenVentCover)
    .clone();
  leftOvenVentCover.position.x = -0.23;
  const rightOvenVentCover = leftOvenVentCover.clone();
  rightOvenVentCover.position.x = 0.23;

  return [
    cupboardDoor,
    ovenDoor,
    storageDrawer,
    leftOvenVentCover,
    rightOvenVentCover,
  ];
}

/**
 * All the classique trim parts for a Volnay range
 * @param {Group} meshes - Common parts of most ranges
 * @returns {Group}
 */
export function classiqueTrim(meshes) {
  const trim = new Group();

  // Towel Bar Supports
  const towelBarSupportLeft = meshes
    .getObjectByName(MeshName.towelBarleftSupport)
    .clone();
  towelBarSupportLeft.position.x = -0.465;
  const towelBarSupportRight = meshes
    .getObjectByName(MeshName.towelBarRightSupport)
    .clone();
  towelBarSupportRight.position.x = 0.465;

  // Cupboard Door Bar Supports
  const cupboardDoorBarSupportLeft = meshes
    .getObjectByName(MeshName.doorBarLeftSupport)
    .clone();
  cupboardDoorBarSupportLeft.name = MeshName.cupboardBarLeftSupport;
  cupboardDoorBarSupportLeft.position.x = -0.413;
  const cupboardDoorBarSupportRight = meshes
    .getObjectByName(MeshName.doorBarRightSupport)
    .clone();
  cupboardDoorBarSupportRight.name = MeshName.cupboardBarRightSupport;
  cupboardDoorBarSupportRight.position.x = -0.213;

  // Oven Door Bar Supports
  const ovenDoorBarSupportLeft = cupboardDoorBarSupportLeft.clone();
  ovenDoorBarSupportLeft.name = MeshName.ovenDoorBarLeftSupport;
  ovenDoorBarSupportLeft.position.x = -0.085;
  const ovenDoorBarSupportRight = cupboardDoorBarSupportRight.clone();
  ovenDoorBarSupportRight.name = MeshName.ovenDoorBarRightSupport;
  ovenDoorBarSupportRight.position.x = 0.45;

  // Cupboard Door Bar
  const cupboardDoorBar = meshes
    .getObjectByName(MeshName.classiqueCupboardDoorBar)
    .clone();
  cupboardDoorBar.name = MeshName.cupboardDoorBar;
  cupboardDoorBar.position.x = -0.313;

  // Oven Door Bar
  const ovenDoorBar = meshes
    .getObjectByName(MeshName.classiqueVolnayOvenDoorBar)
    .clone();
  ovenDoorBar.name = MeshName.ovenDoorBar;

  // Drawer Pulls
  const drawerPullLeft = meshes.getObjectByName(MeshName.drawerPull).clone();
  drawerPullLeft.name = MeshName.drawerLeftPull;
  drawerPullLeft.position.x = 0;
  const drawerPullRight = drawerPullLeft.clone();
  drawerPullRight.name = MeshName.drawerRightPull;
  drawerPullRight.position.x = 0.35;

  return trim.add(
    towelBarSupportLeft,
    towelBarSupportRight,
    cupboardDoorBarSupportLeft,
    cupboardDoorBarSupportRight,
    ovenDoorBarSupportLeft,
    ovenDoorBarSupportRight,
    cupboardDoorBar,
    ovenDoorBar,
    drawerPullLeft,
    drawerPullRight
  );
}

/**
 * All the moderne trim parts for a Volnay range
 * @param {Group} meshes - Common parts of most ranges
 * @returns {Group}
 */
export function moderneTrim(meshes) {
  const trim = new Group();

  // Cupboard Door Bar
  const cupboardDoorBar = meshes
    .getObjectByName(MeshName.moderneVolnayCupboardDoorBar)
    .clone();

  // Oven Door Bar
  const ovenDoorBar = meshes
    .getObjectByName(MeshName.moderneVolnayOvenDoorBar)
    .clone();

  // Cupboard Bar Supports
  const cupboardDoorBarSupportLeft = meshes
    .getObjectByName(MeshName.moderneDoorBarSupport)
    .clone();
  cupboardDoorBarSupportLeft.position.x = -0.42;
  const cupboardDoorBarSupportRight = cupboardDoorBarSupportLeft.clone();
  cupboardDoorBarSupportRight.position.x = -0.21;

  // Oven Bar Supports
  const ovenDoorBarSupportLeft = cupboardDoorBarSupportRight.clone();
  ovenDoorBarSupportLeft.position.x = -0.09;
  const ovenDoorBarSupportRight = ovenDoorBarSupportLeft.clone();
  ovenDoorBarSupportRight.position.x = 0.455;

  // Storage Drawer Lip
  const drawerLip = meshes
    .getObjectByName(MeshName.moderneVolnayDrawerBottomLip)
    .clone();

  return trim.add(
    cupboardDoorBar,
    ovenDoorBar,
    cupboardDoorBarSupportLeft,
    cupboardDoorBarSupportRight,
    ovenDoorBarSupportLeft,
    ovenDoorBarSupportRight,
    drawerLip
  );
}
