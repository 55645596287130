import { Group, Mesh, Object3D } from 'three';

import { Knobs } from './Knobs.js';
import { SSRangeTop } from './SSRangeTop.js';

import { BaseRange } from '../shared/BaseRange.js';
import { KnobModels } from '../shared/KnobModels.js';
import { RangeParts } from '../parts/RangeParts.js';
import { RangeTopOptions } from '../shared/RangeTopOptions.js';

import { AssetLoader } from '../../shared/AssetLoader.js';
import { Materials } from '../../shared/Materials.js';
import { LacancheLogo } from '../../shared/SharedParts.js';
import {
  Burner,
  Cupboard,
  LabelName,
  LabelPosition,
  LabelWidth,
  MeshName,
  Oven,
  RangeTop,
  OptionSpace,
  Region,
} from '../../shared/Enums.js';

export class Fontenay extends BaseRange {
  /**
   * @typedef TopState
   * @type {object}
   * @property {boolean} [fourFeux] - is the range top 4-feux
   * @property {string} [option1] - the first (far left) range top option
   * @property {string} [option2] - the second (far right) range top option
   */

  /** The range top option to the far left of range */
  #option1 = new Group();

  /** The range top option to the far right of range */
  #option2 = new Group();

  /**
   * A dynamic stainless steel range top that assembles the top based on the
   * base burners and selected options
   * @type {SSRangeTop}
   */
  #ssTop;

  /**
   * @param {AssetLoader} assets
   * @param {Materials} materials
   * @param {RangeParts} rangeParts
   * @param {LacancheLogo} sharedParts
   * @param {RangeTopOptions} rangeTopOptions
   * @param {KnobModels} knobModels
   */
  constructor(
    assets,
    materials,
    rangeParts,
    sharedParts,
    rangeTopOptions,
    knobModels
  ) {
    super(assets, materials, rangeParts, rangeTopOptions);

    this.classiqueTrim = rangeParts.fontenayClassiqueTrim();
    this.moderneTrim = rangeParts.fontenayModerneTrim();
    this.knobs = new Knobs(knobModels, this.range, this.state, this.materials);
    this.#loadModels();
    this.applyMaterials();
    this.setupShadows();
    this.state.leftOven = Oven.convection;

    const ssRangeTop = this.#createRangeTop(rangeTopOptions);
    const logo = this.#createLogo(sharedParts);

    this.range.add(
      this.baseBurners,
      this.#option1,
      this.#option2,
      ssRangeTop,
      logo
    );

    this.#setUIFeatures();
    this.#setLabelPositions();
    this.addRangeLabels();
  }

  /**
   * Change the range top style to classique, traditional, or 4-feux
   * @param {string} rangeTop
   * @param {boolean} [assembleTop]
   */
  changeRangeTop(rangeTop, assembleTop = true) {
    this.state.top = rangeTop;
    this.baseBurners.clear();

    switch (rangeTop) {
      case RangeTop.classique: {
        this.add18KBurner(-0.297, this.baseBurners);
        this.add11And5KBurners(0.05, this.baseBurners);
        this.knobs.threeFeuxBaseTop();
        break;
      }

      case RangeTop.traditional: {
        this.addTradPlate(-0.297, this.baseBurners);
        this.add11And5KBurners(0.05, this.baseBurners);
        this.knobs.threeFeuxBaseTop();
        break;
      }

      case RangeTop.fourFeux:
        this.add11KBurners(-0.363, this.baseBurners);
        this.add15And5KBurners(0.053, this.baseBurners);
        this.add4FeuxSpacerGrate(0.0015, this.baseBurners);
        this.knobs.fourFeuxBaseTop();
        break;
    }

    if (assembleTop) {
      this.#updateRangeTop({
        fourFeux: rangeTop === RangeTop.fourFeux,
      });

      this.#refreshRangeTop();
    }
  }

  /**
   * Change the left cupboard to warming or storage
   * @param {string} type
   */
  changeLeftCupboard(type) {
    this.labels.changeText(LabelName.leftCupboard, type);

    switch (type) {
      case Cupboard.warming:
        this.knobs.selectWarmingCupboard(Region.farLeft);
        break;

      case Cupboard.storage:
        this.knobs.selectStorageCupboard(Region.farLeft);
        break;
    }
  }

  /**
   * Change the right cupboard to warming or storage
   * @param {string} type
   */
  changeRightCupboard(type) {
    this.labels.changeText(LabelName.rightCupboard, type);

    switch (type) {
      case Cupboard.warming:
        this.knobs.selectWarmingCupboard(Region.farRight);
        break;

      case Cupboard.storage:
        this.knobs.selectStorageCupboard(Region.farRight);
        break;
    }
  }

  /**
   * Change the one of the optional "burners" on the range top
   * @param {string} burnerOption
   * @param {string} optionSpace
   * @param {boolean} [assembleTop]
   */
  changeBurner(burnerOption, optionSpace, assembleTop = true) {
    const region = this.#knobRegion(optionSpace);
    let xOffset, burnerGroup;

    switch (optionSpace) {
      case OptionSpace.option1:
        xOffset = -0.705;
        burnerGroup = this.#option1;
        break;

      case OptionSpace.option2:
        xOffset = 0.39;
        burnerGroup = this.#option2;
        break;
    }

    this.state[optionSpace] = burnerOption;
    burnerGroup.clear();

    switch (burnerOption) {
      case Burner.two11kBurners:
        this.add11KBurners(xOffset, burnerGroup);
        this.knobs.add2Burners(region);
        break;

      case Burner.two15kBurners:
        this.add15KBurners(xOffset, burnerGroup);
        this.knobs.add2Burners(region);
        break;

      case Burner.one18kBurner:
        this.add18KBurner(xOffset, burnerGroup);
        this.knobs.add1Burner(region);
        break;

      case Burner.flameGrill:
        this.addFlameGrill(xOffset, burnerGroup);
        this.knobs.addFlameGrill(region);
        break;

      case Burner.electricPlancha:
        this.addPlancha(xOffset, burnerGroup);
        this.knobs.addPlancha(region);
        break;

      case Burner.traditionalSimmerPlate:
        this.addTradPlate(xOffset, burnerGroup);
        this.knobs.add1Burner(region);
        break;

      case Burner.multiCooker:
        this.addMultiCooker(xOffset, burnerGroup);
        this.knobs.addMultiCooker(region);
        break;

      case Burner.twoInductionRings:
        this.addInductionRings(xOffset, burnerGroup);
        this.knobs.addInductionRings(region);
        break;

      case Burner.stainlessSteelWorkstation:
        this.knobs.addSSWorkstation(region);
        break;

      default:
        this.knobs.addSSWorkstation(region);
        break;
    }

    if (assembleTop) {
      this.#updateRangeTop({
        [optionSpace]: burnerOption,
      });
    }
  }

  /**
   * All the trim parts of the range that can be brass, brushed stainless steel, chrome, or nickel
   * @returns {Object3D[]}
   */
  trimParts() {
    return [
      this.classiqueTrim.getObjectByName(MeshName.towelBarleftSupport),
      this.classiqueTrim.getObjectByName(MeshName.towelBarMidLeftSupport),
      this.classiqueTrim.getObjectByName(MeshName.towelBarMidRightSupport),
      this.classiqueTrim.getObjectByName(MeshName.towelBarRightSupport),
      this.classiqueTrim.getObjectByName(MeshName.leftCupboardBarLeftSupport),
      this.classiqueTrim.getObjectByName(MeshName.leftCupboardBarRightSupport),
      this.classiqueTrim.getObjectByName(MeshName.ovenDoorBarLeftSupport),
      this.classiqueTrim.getObjectByName(MeshName.ovenDoorBarRightSupport),
      this.classiqueTrim.getObjectByName(MeshName.rightCupboardBarLeftSupport),
      this.classiqueTrim.getObjectByName(MeshName.rightCupboardBarRightSupport),
      this.classiqueTrim.getObjectByName(MeshName.drawerLeftPull),
      this.classiqueTrim.getObjectByName(MeshName.drawerRightPull),
      this.range.getObjectByName(MeshName.logoBorder),
    ];
  }

  /**
   * All the parts of the range that are porcelain enamel
   * @returns {Object3D[]}
   */
  colorParts() {
    return [
      this.range.getObjectByName(MeshName.leftCupboardDoor),
      this.range.getObjectByName(MeshName.rightCupboardDoor),
      this.range.getObjectByName(MeshName.ovenDoor),
      this.range.getObjectByName(MeshName.storageDrawer),
    ];
  }

  /**
   * All the parts of the range that are stainless steel
   * @returns {Object3D[]}
   */
  stainlessSteelParts() {
    return [
      this.range.getObjectByName(MeshName.rangeTopRim1500),
      this.range.getObjectByName(MeshName.backSpacer),
      this.range.getObjectByName(MeshName.toeKick),
      this.range.getObjectByName(MeshName.rangeBase),

      this.classiqueTrim.getObjectByName(MeshName.towelBar),
      this.classiqueTrim.getObjectByName(MeshName.classiqueAerationVent),

      this.moderneTrim.getObjectByName(MeshName.moderneAerationVent),
    ];
  }

  /**
   * All the parts of the range that are galvanized steel
   * @returns {Object3D[]}
   */
  galvanizedSteelParts() {
    return [
      this.range.getObjectByName(MeshName.leftCooktopHingePlate),
      this.range.getObjectByName(MeshName.rightCooktopHingePlate),
    ];
  }

  /**
   * All the parts of the range that receive shadows
   * @returns {Object3D[]}
   */
  shadowCasters() {
    return [this.classiqueTrim.getObjectByName(MeshName.towelBar)];
  }

  /**
   * All the parts of the range that receive shadows
   * @returns {Object3D[]}
   */
  shadowReceivers() {
    return [this.range.getObjectByName(MeshName.controlPanel)];
  }

  /** Set all the optional burners to what they are supposed to be */
  #refreshRangeTop() {
    this.changeOption1(this.state.option1, false);
    this.changeOption2(this.state.option2, false);
  }

  /** Add all the Fontenay meshes to the range Group  */
  #loadModels() {
    // All meshes from the main Fontenay model
    this.assets.fontenayData.scene.children.forEach((child) => {
      if (this.#classiqueMeshNames().includes(child.name)) {
        this.classiqueTrim.add(child.clone());
      } else if (this.#moderneMeshNames().includes(child.name)) {
        this.moderneTrim.add(child.clone());
      } else {
        this.range.add(child.clone());
      }
    });

    this.range.add(this.classiqueTrim);
    this.range.add(...this.rangeParts.fontenayParts());

    // Hide the range top rim. It is only needed to assemble the range top
    this.range.getObjectByName(MeshName.rangeTopRim1500).visible = false;
  }

  /**
   * Update the stainless steel range top with the requested change
   * @param {TopState} topChange
   */
  #updateRangeTop(topChange) {
    const oldRangeTop = this.range.getObjectByName(MeshName.rangeTop);
    this.range.remove(oldRangeTop);

    const newRangeTop = this.#ssTop.assembleSSRangeTop(topChange);
    this.range.add(newRangeTop);
  }

  /**
   * Create a properly configured stainless steel range top
   * @param {RangeTopOptions} rangeTopOptions
   * @returns {Mesh}
   */
  #createRangeTop(rangeTopOptions) {
    this.#ssTop = new SSRangeTop(this.range, rangeTopOptions);

    // Configure optional burners for initial display
    this.#refreshRangeTop();

    return this.#ssTop.assembleSSRangeTop({
      fourFeux: false,
      option1: this.state.option1,
      option2: this.state.option2,
    });
  }

  /**
   * Create the Lacanche logo to show on the oven door
   * @param {LacancheLogo} sharedParts
   * @returns {Group}
   */
  #createLogo(sharedParts) {
    const logo = sharedParts.logo.clone();
    logo.position.x = -0.35;

    return logo;
  }

  /**
   * 3D meshes in the base range that are only part of the classique line
   * @returns {string[]}
   */
  #classiqueMeshNames() {
    return [
      MeshName.classiqueAerationVent,
      MeshName.aerationVentBlack,
      MeshName.towelBar,
    ];
  }

  /**
   * 3D meshes in the base range that are only part of the moderne line
   * @returns {string[]}
   */
  #moderneMeshNames() {
    return [MeshName.moderneAerationVent];
  }

  /**
   * Map the burner option space to the correct region of the control panel
   * @param {string} optionSpace
   * @returns {string}
   */
  #knobRegion(optionSpace) {
    switch (optionSpace) {
      case OptionSpace.option1:
        return Region.farLeft;

      case OptionSpace.option2:
        return Region.farRight;
    }
  }

  /** Set up the user interface to show all the range's configurable features */
  #setUIFeatures() {
    this.features.farLeftOption = true;
    this.features.farRightOption = true;
    this.features.convection = true;
    this.features.leftCupboard = true;
    this.features.rightCupboard = true;
    this.features.fourFeux = true;
  }

  #setLabelPositions() {
    this.labels.config.leftCupboard = {
      position: [-0.55, 0.56, LabelPosition.frontZPos],
      width: LabelWidth.cupboard,
    };
    this.labels.config.leftOven = {
      position: [0, 0.56, LabelPosition.frontZPos],
      width: LabelWidth.sullyOven,
    };
    this.labels.config.leftStorageDrawer = {
      position: [0, LabelPosition.storeDrawerYPos, LabelPosition.frontZPos],
      width: LabelWidth.sullyOven,
    };
    this.labels.config.rightCupboard = {
      position: [0.55, 0.56, LabelPosition.frontZPos],
      width: LabelWidth.cupboard,
    };
  }
}
