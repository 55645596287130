import { Group } from 'three';

import { BaseKnobs } from '../shared/BaseKnobs';
import { KnobModels } from '../shared/KnobModels';

import { Burner, Cupboard, Oven, Region } from '../../shared/Enums';
import { Materials } from '../../shared/Materials';
import { State } from '../../shared/State';

export class Knobs extends BaseKnobs {
  /**
   * The knobs that selected but not necessarily displayed (yet) on the range
   * @type {Object.<string, Object.<string, ?string>>}
   */
  selectedKnobs = {
    farLeft: {
      optionBack: null,
      optionFront: null,
      cupboard: Oven.leftVertConv,
    },
    center: {
      baseLeftBurnerFront: null,
      baseLeftBurnerBack: null,
      baseBurnerCenter: Burner.gasBurner,
      baseRightBurnerBack: Burner.gasBurner,
      baseRightBurnerFront: Burner.gasBurner,
      oven: Oven.convection,
    },
    farRight: {
      cupboard: Cupboard.warming,
      optionBack: null,
      optionFront: null,
    },
  };

  /**
   * @param {KnobModels} knobModels
   * @param {Group} range
   * @param {State} state
   * @param {Materials} materials
   */
  constructor(knobModels, range, state, materials) {
    super(knobModels, range, state, materials);
  }

  threeFeuxBaseTop() {
    this.selectedKnobs.center.baseLeftBurnerFront = null;
    this.selectedKnobs.center.baseLeftBurnerBack = null;
    this.selectedKnobs.center.baseBurnerCenter = Burner.gasBurner;
    this.selectedKnobs.center.baseRightBurnerBack = Burner.gasBurner;
    this.selectedKnobs.center.baseRightBurnerFront = Burner.gasBurner;

    this.updateCenterKnobs();
  }

  fourFeuxBaseTop() {
    this.selectedKnobs.center.baseLeftBurnerFront = Burner.gasBurner;
    this.selectedKnobs.center.baseLeftBurnerBack = Burner.gasBurner;
    this.selectedKnobs.center.baseBurnerCenter = null;
    this.selectedKnobs.center.baseRightBurnerBack = Burner.gasBurner;
    this.selectedKnobs.center.baseRightBurnerFront = Burner.gasBurner;

    this.updateCenterKnobs();
  }

  /**
   * Change the right cupboard to have a knob for a warming cupboard
   * @param {string} region - Ignored since there is only one cupboard
   */
  selectWarmingCupboard(region) {
    this.selectedKnobs.farRight.cupboard = Cupboard.warming;
    this.updateFarRightKnobs();
  }

  /**
   * Remove the warming cupboard knob from the right cupboard
   * @param {string} region - Ignored since there is only one cupboard
   */
  selectStorageCupboard(region) {
    this.selectedKnobs.farRight.cupboard = null;
    this.updateFarRightKnobs();
  }

  /** Update the knobs on the range's far left */
  updateFarLeftKnobs() {
    this.updateKnobRegion(Region.farLeft, {
      maxKnobs: 3,
      offset: 0.32,
      gap: 0.1,
    });
  }

  /** Update the knobs in the center of the range */
  updateCenterKnobs() {
    this.updateKnobRegion(Region.center, {
      maxKnobs: 6,
      offset: 0.67,
      gap: 0.11,
    });
  }

  /** Update the knobs on the range's far right */
  updateFarRightKnobs() {
    this.updateKnobRegion(Region.farRight, {
      maxKnobs: 3,
      offset: 1.39,
      gap: 0.1,
    });
  }

  /**
   * Change the main oven to gas or electric convection
   * @param {string} ovenType
   */
  changeLeftOven(ovenType) {
    this.selectedKnobs.center.oven = ovenType;
    this.updateCenterKnobs();
  }
}
