import { Group } from 'three';

import { BaseKnobs } from '../shared/BaseKnobs';
import { KnobModels } from '../shared/KnobModels';

import { Burner, Oven, Region } from '../../shared/Enums';
import { Materials } from '../../shared/Materials';
import { State } from '../../shared/State';

export class Knobs extends BaseKnobs {
  /**
   * The knobs that selected but not necessarily displayed (yet) on the range
   * @type {Object.<string, Object.<string, ?string>>}
   */
  selectedKnobs = {
    farLeft: {
      optionBack: null,
      optionFront: null,
      cupboard: Oven.leftVertConv,
    },
    right: {
      baseLeftBurnerFront: Burner.gasBurner,
      baseLeftBurnerBack: Burner.gasBurner,
      baseCenterBurner: Burner.gasBurner,
      baseRightBurnerBack: null,
      baseRightBurnerFront: null,
      oven: Oven.convection,
    },
  };

  /**
   * @param {KnobModels} knobModels
   * @param {Group} range
   * @param {State} state
   * @param {Materials} materials
   */
  constructor(knobModels, range, state, materials) {
    super(knobModels, range, state, materials);

    this.updateFarLeftKnobs();
    this.updateRightKnobs();
  }

  threeFeuxBaseTop() {
    this.selectedKnobs.right.baseLeftBurnerFront = null;
    this.selectedKnobs.right.baseLeftBurnerBack = null;
    this.selectedKnobs.right.baseCenterBurner = Burner.gasBurner;
    this.selectedKnobs.right.baseRightBurnerBack = Burner.gasBurner;
    this.selectedKnobs.right.baseRightBurnerFront = Burner.gasBurner;

    this.updateRightKnobs();
  }

  fourFeuxBaseTop() {
    this.selectedKnobs.right.baseLeftBurnerFront = Burner.gasBurner;
    this.selectedKnobs.right.baseLeftBurnerBack = Burner.gasBurner;
    this.selectedKnobs.right.baseCenterBurner = null;
    this.selectedKnobs.right.baseRightBurnerBack = Burner.gasBurner;
    this.selectedKnobs.right.baseRightBurnerFront = Burner.gasBurner;

    this.updateRightKnobs();
  }

  /** Update the knobs on the range's left */
  updateFarLeftKnobs() {
    this.updateKnobRegion(Region.farLeft, {
      maxKnobs: 3,
      offset: 0.53,
      gap: 0.1,
    });
  }

  /** Update the knobs on the range's right */
  updateRightKnobs() {
    this.updateKnobRegion(Region.right, {
      maxKnobs: 5,
      offset: 0.9,
      gap: 0.11,
    });
  }

  /**
   * Change the main oven to gas or electric convection
   * @param {string} ovenType
   */
  changeLeftOven(ovenType) {
    this.selectedKnobs.right.oven = ovenType;
    this.updateRightKnobs();
  }
}
